<template>
  <div>

    <head>
      <title>Trade Meta</title>
    </head>
    <!-- <div class="loader-wrapper" v-if="loading">
    <div class="loader-index"><span></span></div>
  </div> -->

    <div class="loader-wrapper" v-if="loading">
      <div class="logo-container-candle">
        <img ref="logo_candle" src="@/assets/images/logo/logo-white.png" alt="trademeta" class="logo_candle" />
      </div>
      <div class="loading-container">
        <div class="candle green">
          <div class="wick"></div>
          <div class="body_candle"></div>
        </div>
        <div class="candle red">
          <div class="wick"></div>
          <div class="body_candle"></div>
        </div>
        <div class="candle green">
          <div class="wick"></div>
          <div class="body_candle"></div>
        </div>
        <div class="candle red">
          <div class="wick"></div>
          <div class="body_candle"></div>
        </div>
        <div class="candle green">
          <div class="wick"></div>
          <div class="body_candle"></div>
        </div>
        <div class="candle green">
          <div class="wick"></div>
          <div class="body_candle"></div>
        </div>
      </div>

    </div>

    <router-view v-else></router-view>
    <!-- <app-theme-panel /> -->
    <!-- <DepositsModal /> -->
  </div>
</template>
<script>
/* import DepositsModal from "@/views/deposits/modals/DepositsModal.vue"; */

export default {
  components: {
    //DepositsModal,
  },
  name: 'App',
  data() {
    return {
      loading: true,
      showLogo: true,
      GOOGLE_RECAPTCHA_SITE_KEY: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    }
  },
  watch: {
    '$route'() {
      setTimeout(() => {
        this.loading = !this.loading
      }, 1000)
      this.loading = !this.loading
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.onload = () => {
      console.log('Script do reCAPTCHA carregado.');
    };
    script.onerror = (error) => {
      console.error('Erro ao carregar o script do reCAPTCHA:', error);
    };
    document.head.appendChild(script);

    this.loading = false;
    setInterval(() => {
      this.showLogo = !this.showLogo;
    }, 3000);
  }
}
</script>

<style>
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.llogo-container-candle {
  position: relative;
  overflow: hidden;
}

.logo_candle {
  width: 220px;
  /* Ajuste o tamanho da logo conforme necessário */
}

.loading-container {
  height: 30px !important;
  display: flex;
  gap: 10px;
}

.candle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.candle:nth-child(1) .body_candle {
  animation: growShrink 1s infinite alternate;
}

.candle:nth-child(2) .body_candle {
  animation: growShrink 1s infinite alternate;
  animation-delay: 0.6s;
}

.candle:nth-child(3) .body_candle {
  animation: growShrink 1s infinite alternate;
  animation-delay: 0.4s;
}

.candle:nth-child(4) .body_candle {
  animation: growShrink 1s infinite alternate;
  animation-delay: 0.2s;
}

.candle:nth-child(5) .body_candle {
  animation: growShrink 1s infinite alternate;
  animation-delay: 0.1s;
}

.candle:nth-child(6) .body_candle {
  animation: growShrink 1s infinite alternate;
  animation-delay: 0.9s;
}

.wick {
  width: 2px;
  height: 20px;
  background-color: #333;
}

.body_candle {
  width: 20px;
  height: 50px;
}

.green .body_candle {
  background-color: #00a2ff !important;
  /* Verde */
}

.red .body_candle {
  background-color: #d32f2f !important;
  /* Vermelho */
}

@keyframes growShrink {
  0% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}

@keyframes logoOpacity {

  0%,
  100% {
    opacity: 1;
  }

  80% {
    opacity: 0.7;

  }

  50% {
    opacity: 0.5;

  }

  30% {
    opacity: 0.3;

  }

  70% {
    opacity: 0.4;

  }

  80% {
    opacity: 0.6;
  }

  90% {
    opacity: 0.8;
  }
}

.logo-container-candle .logo_candle {
  animation: logoOpacity 2s ease-in-out infinite;
}
</style>
<template>
    <div>
        <div v-if="loadingPage" class="text-center mt-5">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="!loadingPage" class="">
            <div class="w-100"
                :style="{ backgroundImage: 'url(' + require('../../assets/images/dashboard-2/bg-header.jpg') + ')' }"
                style="height: 100%; background-size: cover; background-position: center center; ">
                <div class="container">
                    <div class="">
                        <div class="row widget-grid">
                            <div class="col-12" style="margin-top: 120px !important;">
                                <SimulationCard />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="w-100 h-100 container-custom-dark">
                <div class="container">
                    <div class="row widget-grid">
                        <h4 class="text-white text-center mt-5">
                            Dicas para uma simulação eficiente
                        </h4>
                        <div class="card mt-0">
                            <div class="card-body text-white">
                                <div class="row">
                                    <div class="col-12 col-md-4 p-2">
                                        <div class="text-center border p-0 py-0 rounded h-100 d-flex flex-column">
                                            <img src="@/assets/images/dash-img/image_1.png" alt="trademeta" />
                                            <div class="mt-3 mb-3 px-2 flex-grow-1 p-2 py-3">

                                                <!-- <div class="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                                        fill="currentColor" class="bi bi-rocket text-success"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 8c.828 0 1.5-.895 1.5-2S8.828 4 8 4s-1.5.895-1.5 2S7.172 8 8 8" />
                                                        <path
                                                            d="M11.953 8.81c-.195-3.388-.968-5.507-1.777-6.819C9.707 1.233 9.23.751 8.857.454a3.5 3.5 0 0 0-.463-.315A2 2 0 0 0 8.25.064.55.55 0 0 0 8 0a.55.55 0 0 0-.266.073 2 2 0 0 0-.142.08 4 4 0 0 0-.459.33c-.37.308-.844.803-1.31 1.57-.805 1.322-1.577 3.433-1.774 6.756l-1.497 1.826-.004.005A2.5 2.5 0 0 0 2 12.202V15.5a.5.5 0 0 0 .9.3l1.125-1.5c.166-.222.42-.4.752-.57.214-.108.414-.192.625-.281l.198-.084c.7.428 1.55.635 2.4.635s1.7-.207 2.4-.635q.1.044.196.083c.213.09.413.174.627.282.332.17.586.348.752.57l1.125 1.5a.5.5 0 0 0 .9-.3v-3.298a2.5 2.5 0 0 0-.548-1.562zM12 10.445v.055c0 .866-.284 1.585-.75 2.14.146.064.292.13.425.199.39.197.8.46 1.1.86L13 14v-1.798a1.5 1.5 0 0 0-.327-.935zM4.75 12.64C4.284 12.085 4 11.366 4 10.5v-.054l-.673.82a1.5 1.5 0 0 0-.327.936V14l.225-.3c.3-.4.71-.664 1.1-.861.133-.068.279-.135.425-.199M8.009 1.073q.096.06.226.163c.284.226.683.621 1.09 1.28C10.137 3.836 11 6.237 11 10.5c0 .858-.374 1.48-.943 1.893C9.517 12.786 8.781 13 8 13s-1.517-.214-2.057-.607C5.373 11.979 5 11.358 5 10.5c0-4.182.86-6.586 1.677-7.928.409-.67.81-1.082 1.096-1.32q.136-.113.236-.18Z" />
                                                        <path
                                                            d="M9.479 14.361c-.48.093-.98.139-1.479.139s-.999-.046-1.479-.139L7.6 15.8a.5.5 0 0 0 .8 0z" />
                                                    </svg>
                                                </div> -->
                                                <!--                     <p class="text-success font-size-16 mb-0">Por onde começar?</p> -->
                                                <p class="text-white mb-0 font-size-13">
                                                    Veja todos os nossos artigos com dicas exclusivas para suas
                                                    simulações.
                                                </p>
                                            </div>
                                            <a href="https://app.trademeta.com.br/registrer"
                                                class="btn btn-outline-theme align-self-center mb-2 text-white"
                                                target="_blank">
                                                Leia a matéria completa
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 p-2">
                                        <div class="text-center border p-0 py-0 rounded h-100 d-flex flex-column">
                                            <img src="@/assets/images/dash-img/image_2.png" alt="trademeta" />
                                            <div class="mt-3 mb-3 px-2 flex-grow-1 p-2 py-3">
                                                <!-- <div class="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                                        fill="currentColor" class="bi bi-person-lines-fill text-success"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
                                                    </svg>
                                                </div> -->
                                                <!--        <p class="text-success font-size-16 mb-0">Nossos especialistas</p> -->
                                                <p class="text-white mb-0 font-size-13">
                                                    Acompanhe o ponto de vista de mercado diretamente com nossos
                                                    especialistas.
                                                </p>
                                            </div>
                              
                                            <a href="https://app.trademeta.com.br/registrer"
                                                class="btn btn-outline-theme align-self-center mb-2 text-white"
                                                target="_blank">
                                                Papo com os nossos especialistas
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 p-2">
                                        <div class="text-center border p-0 py-0 rounded h-100 d-flex flex-column">
                                            <img src="@/assets/images/dash-img/image_3.png" alt="trademeta" />
                                            <div class="mt-3 mb-3 px-2 flex-grow-1 p-2 py-3">
                                                <!--   <div class="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                                        fill="currentColor"
                                                        class="bi bi-fast-forward-btn-fill text-success"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M0 4v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2m4.271 1.055a.5.5 0 0 1 .52.038L8 7.386V5.5a.5.5 0 0 1 .79-.407l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 8 10.5V8.614l-3.21 2.293A.5.5 0 0 1 4 10.5v-5a.5.5 0 0 1 .271-.445" />
                                                    </svg>
                                                </div> -->
                                                <!--                                         <p class="text-success font-size-16 mb-0">Vídeo aulas exclusivas</p> -->
                                                <p class="text-white mb-0 font-size-13">
                                                    Um acervo especial de vídeos com os melhores profissionais de
                                                    mercado.
                                                </p>
                                            </div>
                                            <a href="https://app.trademeta.com.br/registrer"
                                                class="btn btn-outline-theme align-self-center mb-2 text-white"
                                                target="_blank">
                                                Assista nossos vídeos
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Nosos planos -->
            <div class="w-100  h-100 container-custom-gray-400">
                <SubscriptionPlansCard />
            </div>
            <!-- O que as pessoas ... -->
            <div class="w-100 h-100 container-custom-dark" v-if="false">
                <div class="container">
                    <div class="row widget-grid">
                        <h4 class="text-white text-center mt-5">
                            <strong>O que as pessoas andam falando</strong>
                            sobre nosso simulador
                        </h4>
                        <p class="text-center">
                            Acompanhe alguns depoimentos de pessoas que já assinam nosso serviço
                        </p>
                        <CommentsDash />
                    </div>
                </div>
            </div>
            <!-- Precisa da nossa ajuda? -->
            <div class="w-100 h-100 py-4 container-custom-gray-400" v-if="false">
                <div class="container">
                    <div class="row widget-grid">
                        <h4 class="text-white text-center mt-5">
                            Precisa da nossa ajuda?
                        </h4>
                        <p class="text-center">
                            Nossos especialistas estão a disposição para qualquer tipo de dúvida que você tiver.
                            <br>
                            Estaremos atendendo em horário comercial das 8h às 19h de segunda à sexta-feira exceto nos
                            feriados.
                        </p>
                        <div class="text-center mb-3 d-flex justify-content-center gap-3">
                            <!-- botões abra um chamado e outro fale com a gente -->

                            <button class="btn btn-outline-theme mt-2 opacity-8 text-nowrap">Abra um
                                chamado</button>
                            <button class="btn btn-theme mt-2 opacity-8 text-white">Fale com a gente</button>

                        </div>
                        <!-- Perguntas mais frequentes -->
                        <h4 class="text-white text-center mt-5">
                            Perguntas mais frequentes
                        </h4>
                        <p class="text-center">
                            Ainda tem dúvidas? Veja se podemos te ajudar com essas respostas que separamos pra você.
                        </p>
                        <div class="card-body text-white">
                            <div class="default-according style-1" id="accordion1">
                                <div class="card">
                                    <div class="card-header bg-dark" id="heading1">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link text-white" data-bs-toggle="collapse"
                                                data-bs-target="#collapse1" aria-expanded="false"
                                                aria-controls="collapse1">
                                                Eu terei acesso imediato ao simulador e aos conteúdos explicativos assim
                                                que assinar o plano?
                                            </button>
                                        </h5>
                                    </div>
                                    <div class="collapse show" id="collapse1" aria-labelledby="heading1"
                                        data-bs-parent="#accordion1">
                                        <div class="card-body text-white">Anim pariatur cliche reprehenderit, enim
                                            eiusmod high
                                            life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                            cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                            farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                                            of them accusamus labore sustainable VHS.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header bg-dark" id="headingF2">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-white" data-bs-toggle="collapse"
                                                data-bs-target="#collapse2" aria-expanded="false"
                                                aria-controls="collapse2">
                                                Posso cancelar o plano a qualquer momento?
                                            </button>
                                        </h5>
                                    </div>
                                    <div class="collapse" id="collapse2" aria-labelledby="headingF2"
                                        data-bs-parent="#accordion1">
                                        <div class="card-body text-white">Anim pariatur cliche reprehenderit, enim
                                            eiusmod high
                                            life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                            cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                            farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                                            of them accusamus labore sustainable VHS.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header bg-dark" id="heading3">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-white" data-bs-toggle="collapse"
                                                data-bs-target="#collapse3" aria-expanded="false"
                                                aria-controls="collapse3">
                                                O acesso aos conteúdos é vitalício ou tem um prazo de validade a partir
                                                do momento da assinatura?
                                            </button>
                                        </h5>
                                    </div>
                                    <div class="collapse" id="collapse3" aria-labelledby="heading3"
                                        data-bs-parent="#accordion1">
                                        <div class="card-body text-white">Anim pariatur cliche reprehenderit, enim
                                            eiusmod high
                                            life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                            cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                            farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                                            of them accusamus labore sustainable VHS.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header bg-dark" id="heading4">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-white" data-bs-toggle="collapse"
                                                data-bs-target="#collapse4" aria-expanded="false"
                                                aria-controls="collapse4">
                                                Posso tirar algumas dúvidas diretamente com os especialistas do Trade
                                                Meta?
                                            </button>
                                        </h5>
                                    </div>
                                    <div class="collapse" id="collapse4" aria-labelledby="heading4"
                                        data-bs-parent="#accordion1">
                                        <div class="card-body text-white">Anim pariatur cliche reprehenderit, enim
                                            eiusmod high
                                            life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                            cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                            farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                                            of them accusamus labore sustainable VHS.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header bg-dark" id="heading5">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-white" data-bs-toggle="collapse"
                                                data-bs-target="#collapse5" aria-expanded="false"
                                                aria-controls="collapse5">
                                                Como funciona a taxa de assertividade do simulador para que é assinante?
                                            </button>
                                        </h5>
                                    </div>
                                    <div class="collapse" id="collapse5" aria-labelledby="heading5"
                                        data-bs-parent="#accordion1">
                                        <div class="card-body text-white">Anim pariatur cliche reprehenderit, enim
                                            eiusmod high
                                            life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                            cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                            farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                                            of them accusamus labore sustainable VHS.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimulationCard from "./default/SimulationCard.vue";
import PurchaseSaleCard from "./default/PurchaseSaleCard.vue";
import OverallBalanceCard from "./default/OverallBalanceCard.vue";
import RecentOrdersCard from "./default/RecentOrdersCard.vue";
import CommentsDash from "../../components/dahsboard/comments-dash.vue";
import SubscriptionPlansCard from "../subscription/SubscriptionPlansCard.vue";

import copy from "copy-text-to-clipboard";
import { mapGetters } from "vuex";


export default {
    components: {
        SimulationCard,
        PurchaseSaleCard,
        OverallBalanceCard,
        RecentOrdersCard,
        CommentsDash,
        SubscriptionPlansCard,
    },

    data() {
        return {
            copyTextarea: null,
            referenceLink: "",
            dashboardMyResults: null,
            loadingPage: true,
            fullname: null,
            email: null,
            avatarSrc: null,

        }
    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    mounted() {
        this.loadingPage = false;
        this.fullname = this.returnUser?.fullname;
        this.email = this.returnUser?.email;
        if (this.returnUser?.avatar) {
            this.avatarSrc = this.returnUser?.avatar
                ? `${process.env.VUE_APP_API_URL}/avatar/${this.returnUser?.avatar}`
                : null;
        }
    },
    methods: {

    }
};
</script>

<style>
.custom-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

.custom-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.custom-slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.custom-slider {
    background-color: #00a2ff;
}

input:checked+.custom-slider:before {
    transform: translateX(17px);
}
</style>

<template>
  <div>
    <input class="form-control py-2 dash-input-custom mr-2 mt-2 price" v-model="inputValue" type="text"
      :placeholder="placeholder" @blur="onBlur" :style="`width: 170px; ${curstomStyle}`" @focus="onFocus"
      @input="formatCurrency" :disabled="disabled" />
  </div>
</template>
<script>
export default {
  name: 'InputFormatPrice',
  props: {
    modelValue: {
      type: String,
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      default: '0,00'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    curstomStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.modelValue
    };
  },
  watch: {
    modelValue(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
  methods: {
    formatCurrency() {
      if (this.inputValue) {
        let value = this.inputValue.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        if (value) {
          value = (parseInt(value) / 100).toFixed(2); // Divide por 100 para obter o valor correto
          const formatter = new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          this.inputValue = formatter.format(value);
        } else {
          this.inputValue = '';
        }
      }

    },
    onBlur() {
      this.formatCurrency();
    },
    onFocus() {
      if (this.inputValue) {
        let value = this.inputValue.replace(/\D/g, '');
        if (value) {
          value = (parseInt(value) / 100).toFixed(2);
          this.inputValue = value.replace('.', ',');
        } else {
          this.inputValue = '';
        }
      }
    }
  }
};
</script>
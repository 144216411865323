<template>
  <div class="navbar-brand p-0">
    <router-link to="/">
      <img class="logo-brand" src="../../assets/images/logo/logo-white.png" alt />
    </router-link>
  </div>

</template>

<script>
export default {
  name: 'Logo',
  methods: {
    toggle_sidebar() {
      this.$store.dispatch('opensidebar');
    },
  },
};
</script>

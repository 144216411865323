<template>
  <div class="page-header close_icon">
    <div class="header-wrapper row m-0 p-0 pt-3">
      <header data-bs-theme="dark" class="px-3">
        <header
          class="container-xxl navbar navbar-expand-md navbar-dark bd-navbar navbar-sticky rounded p-1 px-2 navbar-boder">
          <nav class="container-xxl flex-wrap flex-md-nowrap p-1" aria-label="Main navigation">
            <Logo />
            <button class="navbar-toggler p-2" type="button" @click="toggleNavbar()" href="#" id="navbarDropdown"
              role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i data-name="list" data-tags="options" data-type="list" class="vue-feather vue-feather--list"><svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-list vue-feather__content">
                  <line x1="8" y1="6" x2="21" y2="6"></line>
                  <line x1="8" y1="12" x2="21" y2="12"></line>
                  <line x1="8" y1="18" x2="21" y2="18"></line>
                  <line x1="3" y1="6" x2="3.01" y2="6"></line>
                  <line x1="3" y1="12" x2="3.01" y2="12"></line>
                  <line x1="3" y1="18" x2="3.01" y2="18"></line>
                </svg></i>
            </button>
            <div class="collapse navbar-collapse" id="bdNavbar">
              <ul class="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0">
                <li class="nav-item col-6 col-md-auto ">

                  <!-- <a class="nav-link p-2 active" href="#">Home</a> -->
                  <router-link to="/" class="nav-link p-2 active">{{ $t("Home") }}</router-link>

                </li>
                <li class="nav-item col-6 col-md-auto" v-if="false">
                  <a class="nav-link p-2" aria-current="true" href="#for_you">
                    {{ $t("Como Funciona") }}
                  </a>
                </li>
                <li class="nav-item col-6 col-md-auto" v-if="false">
                  <!--  <router-link class="nav-link p-2" :to="{ name: 'courses-list' }">
                    {{ $t("Materiais de Estudo") }}
                  </router-link> -->
                  <router-link class="nav-link p-2" to="/">
                    {{ $t("Materiais de Estudo") }}
                  </router-link>
                </li>

                <li class="nav-item col-6 col-md-auto">
                  <router-link class="nav-link p-2" :to="{ name: 'subscriptionPlans' }">
                    {{ $t("Planos de Assinatura") }}
                  </router-link>
                </li>
                <!-- <li class="nav-item col-6 col-md-auto">
                  <a class="nav-link p-2 " aria-current="true" href="/speed">{{ $t("Suporte") }}</a>
                </li> -->
              </ul>

              <!-- Falar com vendedor -->
              <ul class="navbar-nav flex-row flex-wrap ms-md-auto mt-3 mt-md-0">
                <!-- <li class="text-center m-0">
                  <div style="width: 120px !important;">
                    <div class="mt-0">
                      <strong class="font-size-15">
                        {{
                          returnUser ? moneyFormatUSDT(returnUser.balance) : 'Carregando...'
                        }}
                      </strong>
                    </div>
                  </div>
                </li> -->

                <li class="language-nav">
                  <Language />
                </li>

                <li class="p-0 mt-0 mb-0 col-auto w-md-100 text-center mt-2 mt-md-0 mb-3 mb-md-0">
                  <a class="btn btn-outline-theme  py-2 text-white" href="https://app.trademeta.com.br/login"
                    target="_self">
                    {{ $t("Acessar sua conta") }}
                  </a>
                </li>
                <li class="p-0 mt-0 mb-0 col-auto w-md-100 text-center  mt-md-0 mb-3 mb-md-0">
                  <a class="btn btn-theme  py-2 text-white" href="https://app.trademeta.com.br/registrer"
                    target="_self">
                    {{ $t("Criar conta grátis") }}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </header>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import Bookmark from '../bookmark';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Language from './language';
import Notifications from './notifications.vue';
import Mode from './mode';
import Cart from './cart';
import Profile from './profile';
import Logo from './logo.vue';
import SearchBar from './search';
import { Modal } from 'bootstrap';
export default {
  components: {
    Bookmark, Language, Notifications, Mode, Cart, Profile, Logo, Swiper, SwiperSlide, SearchBar
  },
  data() {
    return {
      bookmark: false,
      currentSignedPlan: null,
      // ultimo plano
      activePlansUpgrade: false,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
    ...mapGetters(["returnUser", "returnModalWithdrawRequestInstance"])
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.testButtonPlanUpgrade();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  watch: {
    returnUser: {
      handler(value) {
        this.testButtonPlanUpgrade();
      }, deep: true
    }
  },
  methods: {
    testButtonPlanUpgrade() {
      if (this.returnUser && this.returnUser?.subscription_detail?.subscription_plans_id < 3) {
        this.activePlansUpgrade = true;
      }
    },
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },
    routeSubscriptionPlans() {
      //subscription-plans
      this.$router.push('/subscription-plans');
      // if (!this.returnModalWithdrawRequestInstance) {
      //   let modalInstance = new Modal(document.getElementById('modalDeposits'), {
      //     backdrop: 'static',
      //     keyboard: false,
      //   });
      //   modalInstance.show();
      //   this.$store.dispatch('openModalWithdrawRequest', modalInstance);
      // } else {
      //   this.returnModalWithdrawRequestInstance.show();
      // }
    },
    openModalDepositsRequest() {
      let modalInstanceDeposit = new Modal(document.getElementById('modalDeposits'), {
        backdrop: 'static',
        keyboard: false,
      });
      modalInstanceDeposit.show();
    },
    toggleNavbar() {
      var navbar = document.querySelector('.navbar-collapse');
      navbar.classList.toggle('show');
    },
    handleClickOutside(event) {
      var navbar = document.querySelector('.navbar-collapse');
      var navbarToggler = document.querySelector('.navbar-toggler');

      if (navbar.classList.contains('show') && !navbar.contains(event.target) && !navbarToggler.contains(event.target)) {
        navbar.classList.remove('show');
      }
    }
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style>
.btn-pers {
  background: linear-gradient(103.75deg, #33B1EE -13.9%, var(--theme-deafult) 79.68%);
  color: #fff;
}

.btn-pers:hover {
  background: linear-gradient(103.75deg, #0a8fd1 -13.9%, var(--theme-deafult) 79.68%);
  color: #fff;
}

.btn-pers-green {
  background: linear-gradient(103.75deg, #28a745 -13.9%, var(--theme-deafult-green) 79.68%);
  color: #fff;
}

.btn-pers-green:hover {
  background: linear-gradient(103.75deg, #198b34 -13.9%, var(--theme-deafult-green) 79.68%);
  color: #fff;
}
</style>
<template>
  <div>
    <div class="logo-wrapper">
      <div class="back-btn" @click="toggle_sidebar">
        <vue-feather class="status_toggle middle sidebar-toggle text-white pointer" type="grid"
          id="sidebar-toggle"></vue-feather>
      </div>
      <div class="d-flex justify-content-center align-items-end mb-4" style="height: 180px !important;">
        <Logo />
      </div>

      <div class="toggle-sidebar " @click="toggle_sidebar">
        <vue-feather class="status_toggle middle sidebar-toggle text-white pointer" type="grid"
          id="sidebar-toggle"></vue-feather>
      </div>
    </div>
    <div class="logo-icon-wrapper">
      <router-link to="/">
        <img class="img-fluid" src="../../assets/images/logo/favicon-white.png" alt=""
          style="width: 30px; height: 30px;" /></router-link>
    </div>
    <Nav />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Logo from './logo.vue';
import Nav from './nav.vue';

export default {
  name: 'Sidebar',
  components: {
    Logo,
    Nav,
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      activeoverlay: (state) => state.menu.activeoverlay,
    }),
  },
  mounted() {
    this.toggle_sidebar()
  },
  methods: {
    toggle_sidebar() {
      this.$store.dispatch('opensidebar');
      this.$store.state.menu.activeoverlay = false;
    },
  },
};
</script>

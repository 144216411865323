import api from "@/libs/axios";

const subscriptionsPlans = {
    state: {
        subscriptionsPlans: [],
        planSelectedPurchase: null,
    },
    getters: {
        returnSubscriptionsPlans: state => state.subscriptionsPlans,
        returnPlanSelectedPurchase: state => state.planSelectedPurchase,
    },
    actions: {
        planSelectedPurchase({ commit }, purchase) {
            //console.log(purchase);
            commit("ADD_PLAN_PURCHASE", purchase);
        },
        /* buscar planos */
        subscriptionsPlansGet: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/subscriptions-public/plans`).then(response => {
                    resolve(response?.data)
                }).catch(error => {
                    reject(error.response?.data)
                })
            })
        },
        subscriptionsPlansSubscribe: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(`/subscriptions-public/subscribe`, data).then(response => {
                    commit("ADD_PLAN_PURCHASE", response?.data);
                    resolve(response?.data)
                }).catch(error => {
                    reject(error.response?.data)
                })
            })
        }
    },
    mutations: {
        SET_SIMULATION_TYPES: (state, subscriptionsPlans) => {
            state.subscriptionsPlans = subscriptionsPlans
        },
        ADD_PLAN_PURCHASE: (state, purchase) => {
            state.planSelectedPurchase = purchase
        }
    },
}

export default subscriptionsPlans;
<template>
  <li class="onhover-dropdown" style="">
    <div class="notification-box" style="position: relative;">
      <svg style="vertical-align: middle;
    width: 25px;
    height: 25px;
    stroke: #fff;
    fill: none;
    overflow: hidden;
    ">
        <use href="@/assets/svg/icon-sprite.svg#notification" @click="notification_open()"></use>
      </svg>

      <span class="badge rounded-pill badge-secondary" style="position: absolute;
    right: -5px;
    top: -6px;
    padding: 2px 4px;
    font-size: 11px;
    font-weight: 700;
    font-family: work-Sans, sans-serif;">0 </span>
    </div>
    <div class="notification-dropdown onhover-show-div" :class="{ active: notification }" style="">
      <h6 class="f-18 mb-0 dropdown-title">
        Notitications
      </h6>
      <ul class="">
        <!-- <li class="b-l-primary border-4">
          <p class="m-1">Delivery processing <span class="font-danger">10 min.</span></p>
        </li>
        <li class="b-l-success border-4">
          <p class="m-1">Order Complete<span class="font-success">1 hr</span></p>
        </li>
        <li class="b-l-info border-4">
          <p class="m-1">Tickets Generated<span class="font-info">3 hr</span></p>
        </li>
        <li class="b-l-warning border-4">
          <p class="m-1">Delivery Complete<span class="font-warning">6 hr</span></p>
        </li> -->
        <li class="">
          <p class="m-1 w-100 text-center">
            <span class="font-primary">-</span>
          </p>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>

export default {
  name: 'Notifications',
  data() {
    return {
      notification: false,
    };
  },
  methods: {
    notification_open() {
      this.notification = !this.notification;
    },
  },
};
</script>

export const statusPayClass = (status) => {
    if (status == 'purchase_cancelled') {
        return 'rounded text-center p-1 bg-danger text-white';
    } else if (status == 'purchase_expired') {
        return 'rounded text-center p-1 bg-secondary text-white';
    } else if (status == 'purchase_released') {
        return 'rounded text-center p-1 bg-success text-white';
    } else if (status == 'payment_pending') {
        return 'rounded text-center p-1 bg-warning text-white';
    } else if (status == 'payment_rejected') {
        return 'rounded text-center p-1 bg-danger text-white';
    } else if (status == 'payment_processing_refund') {
        return 'rounded text-center p-1 bg-warning text-white';
    } else if (status == 'payment_refunded') {
        return 'rounded text-center p-1 bg-warning text-white';
    } else if (status == 'under_review') {
        return 'rounded text-center p-1 bg-warning text-white';
    } else if (status == 'budget_send') {
        return 'rounded text-center p-1 bg-warning text-white';
    } else if (status == 'budget_canceled') {
        return 'rounded text-center p-1 bg-danger text-white';
    } else if (status == 'in_mediation') {
        return 'rounded text-center p-1 bg-warning text-white';
    } else if (status == 'charged_back') {
        return 'rounded text-center p-1 bg-danger text-white';
    }
}

//status schedule
export const statusScheduleClassBG = (status) => {
    switch (status) {
        case 'scheduled':
            return 'rounded bg-secondary-shedule text-white';
        case 'confirmed':
            return 'rounded bg-success text-white';
        case 'on_hold':
            return 'rounded bg-pink-shedule text-white';
        case 'in_progress':
            return 'rounded bg-warning text-white';
        case 'completed':
            return 'rounded bg-primary text-white';
        case 'missing':
            return 'rounded bg-danger text-white';
        case 'blocked':
            return 'rounded bg-dark text-white';
        //canceled
        case 'removed':
            return 'rounded bg-danger text-white';
        default:
            return 'text-white';
    }
}

//status scheduleTextcolor
export const statusScheduleClassText = (status) => {
    switch (status) {
        case 'scheduled':
            return [
                {
                    typeStatus: 'confirmed',
                    class: 'text-primary'
                },
                {
                    typeStatus: 'missing',
                    class: 'text-danger'
                }
            ];
        case 'confirmed':
            return [
                {
                    typeStatus: 'on_hold',
                    class: 'text-pink'
                }
            ];
        case 'on_hold':
            return [
                {
                    typeStatus: 'in_progress',
                    class: 'text-warning'
                }
            ];
        case 'in_progress':
            return [
                {
                    typeStatus: 'completed',
                    class: 'text-success'
                }
            ];
        default:
            return [];

    }
}

//status patient
export const statusPatientClass = (status) => {
    switch (status) {
        case 'active':
            return 'rounded p-1 px-1 bg-success text-white';
        case 'inactive':
            return 'rounded p-1 px-1 bg-secondary-shedule text-white';
        case 'removed':
            return 'rounded p-1 px-1 bg-danger text-white';
        default:
            return 'text-white';
    }
}

// class Status geral


export const classStatusGeneral = (status) => {
    //open,pending,completed,canceled
    switch (status) {
        case 'active':
            return 'bg-primary';
        case 'pending':
            return 'bg-warning';
        case 'completed':
            return 'bg-success';
        case 'canceled':
            return 'bg-danger';
        case 'inactive':
            return 'bg-secondary-shedule';
        case 'waiting_activation':
            return 'bg-warning';
        default:
            break;
    }
}


<template>
  <router-link to="/" class="d-flex justify-content-center">
    <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logo.png"
      alt=""
    /><img
      class="img-fluid for-dark "  style="width: 55% !important;"
      src="../../assets/images/logo/logo_dark.png"
      alt=""
    />
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>

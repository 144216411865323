<template>
  <div>
    <footer class="footer-dark">
      <div class="w-100 h-100">
        <div class="container py-3">
          <div class="row">
            <div class="col-12 py-2 col-md-4 text-start">
              <p>
                <Logo />
              </p>

              <!-- endereço -->
              <p class="text-start">
                <span>
                  <strong>Cnpj:</strong> 56.086.338/0001-62
                  <br>
                  <strong>Telefone:</strong> (41) 3339-2990
                  <br>
                  <strong>E-mail:</strong> contato@trademeta.com.br
                  <br>
                  <strong>Endereço:</strong> Rua Euclides Bandeira
                  <br>
                  <strong>Nº</strong> 2056, AHÚ, Curitiba/PR
                  <br>
                  <strong>Cep:</strong> 80530-020
                </span>
              </p>
            </div>
            <div class="col-6  col-md-2">

            </div>
            <div class="col-6  col-md-2">

            </div>
            <div class="col-12 col-md-4">
              <p class="text-center">
                <strong>
                  Download (coming soon)
                </strong>
              </p>
              <div class="d-flex justify-md-content-center align-items-md-center w-100 gap-3">
                <div class="row align-items-center card-mini-custom-gray rounded mb-1"
                  style="width: 200px; margin-right: 5px !important; margin-left: 1px">
                  <div class="col-auto">
                    <svg width="34" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M23.1246 18.0672C23.0814 13.7602 26.6366 11.6944 26.7953 11.5905C24.7977 8.66892 21.6852 8.26804 20.5769 8.22289C17.9307 7.95354 15.4101 9.7818 14.0671 9.7818C12.7274 9.7818 10.6529 8.26205 8.45823 8.30048C5.57038 8.34371 2.90939 9.98021 1.42327 12.5651C-1.57521 17.7664 0.656596 25.4754 3.57822 29.6975C5.00692 31.7607 6.71029 34.0829 8.94483 33.9979C11.099 33.9128 11.9123 32.6042 14.5159 32.6042C17.1196 32.6042 17.8512 33.9979 20.1296 33.954C22.4458 33.9121 23.9143 31.851 25.3315 29.7792C26.9709 27.382 27.6464 25.0611 27.6862 24.943C27.6361 24.92 23.171 23.2091 23.1246 18.0672ZM18.8432 5.42881C20.0283 3.98998 20.8313 1.99036 20.6134 0.00012207C18.9026 0.0695842 16.832 1.13731 15.6044 2.5748C14.5043 3.85032 13.542 5.88098 13.7998 7.83538C15.7076 7.98324 17.654 6.86498 18.8432 5.42881Z"
                        fill="#D3D4DC" />
                    </svg>
                  </div>
                  <div class="col py-1">
                    <span>
                      Download
                      <br>
                      <strong>
                        App Store
                      </strong>
                    </span>
                  </div>
                </div>
                <div class="row align-items-center card-mini-custom-gray rounded mb-1"
                  style="width: 200px; ; margin-right: 1px">
                  <div class="col-auto">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M28.586 15.1148C29.7315 15.9681 29.7315 17.8899 28.6578 18.7433L25.6357 20.4546L21.1572 16.9291L25.6467 13.4708L28.586 15.1148Z"
                        fill="#FFC801" />
                      <path
                        d="M25.6591 20.438L25.6584 20.4373H25.6578L21.1531 16.931L21.1517 16.9297L21.151 16.9303L21.1491 16.9283L21.1332 16.9404L4.54556 29.5742V30.1021C4.5455 30.7995 4.70872 31.5434 5.26574 31.8018C5.99966 32.1421 7.07046 31.4769 7.07046 31.4769L25.6591 20.438Z"
                        fill="#F93647" />
                      <path d="M21.1332 16.9403L4.54562 29.5741V4.02356L21.1293 16.935L21.1332 16.9403Z"
                        fill="#00D3FF" />
                      <path
                        d="M4.54562 3.76792V4.02358L21.1292 16.935L21.1332 16.9403L21.1491 16.9283L21.1505 16.9276L21.1571 16.9223L25.6467 13.4901C25.6467 13.4901 11.2078 4.85784 7.02205 2.36827C6.83996 2.25996 6.4036 1.98524 5.88039 2.15657C5.25358 2.3619 4.54562 3.10751 4.54562 3.76792Z"
                        fill="#00EF77" />
                    </svg>
                  </div>
                  <div class="col py-1">
                    <span>
                      Download
                      <br>
                      <strong>
                        Google Play
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 footer-copyright text-center py-2 container">
            <p class="mb-0">
              © 2024 Suscitar, Made with ❤️ for a better web.
              {{ new Date().getFullYear() }} © Trademeta - v{{ appVersion }}</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { version } from '../../package.json'
import Logo from './header/logo.vue'
export default {
  name: 'footerpage',
  components: {
    Logo
  },
  data() {
    return {
      customizer: false,
      appVersion: version,
    };
  },
  computed: {
    ...mapGetters({
      footer: 'layout/footer'
    })
  }
};
</script>

<template>

    <!-- <BreadCrumbsClean :title="{
        name: 'Plans',
    }" /> -->
    <div v-if="loadingPage" class="text-center mt-5">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else class="container-fluid">
        <SubscriptionPlansCard />
    </div>
</template>

<script>
import SubscriptionPlansCard from "../subscription/SubscriptionPlansCard.vue";
import { mapGetters } from "vuex";

export default {
    name: 'subscriptionPlans',

    components: {
        SubscriptionPlansCard,
    },
    data() {
        return {
            loadingPage: true,
        }
    },
    computed: {
  
    },
    mounted() {
        setTimeout(() => {
            this.loadingPage = false;
        }, 500);
    }
}
</script>

<template>
    <div>
        <div class="w-100">
            <div v-if="!resultSimulation" class="text-center mt-2" style="margin-left: 50px !important;
            margin-right: 50px !important;
            ">
                <h4 class="text-white custom-tooltip">
                    Faça uma simulação rápida!
                </h4>

                <p class="mb-5">
                    Escolha o tipo de mercado para sua simulação.
                </p>
                <div class="mb-3">
                    <div v-if="listTradeTypeLoading" class="d-flex justify-content-center">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-if="!listTradeTypeLoading">
                        <form @submit.prevent="submitSimulation" name="login_form" class="form-align ">
                            <div v-if="tradeTypesList.length > 0 && !listTradeTypeLoading"
                                class="row justify-content-center gap-3">
                                <div v-for="(tradeType, index) in tradeTypesList" :key="tradeType.uuid"
                                    class="col-md-auto d-flex justify-content-center">
                                    <div class="radio radio-success">
                                        <input :id="'radio' + tradeType.uuid" type="radio" name="tradeType"
                                            :value="tradeType.uuid" v-model="simulation_uuid"
                                            :disabled="!email_verified">
                                        <label :for="'radio' + tradeType.uuid">
                                            <span class="fw-bolder">
                                                {{ tradeType.name }}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div v-if="!listTradeTypeLoading && tradeTypesList.length == 0"
                                class="d-flex justify-content-center">
                                <div class="alert alert-danger" role="alert">
                                    Não foi possível carregar os tipos de simulação.
                                </div>
                            </div>

                            <div class="row mt-3">
                                <!-- plan light -->
                                <div class="col-12 px-4 d-md-flex justify-content-md-center gap-3">
                                    <div class="col-md-auto d-flex justify-content-center">
                                        <div role="group" class="form-group">
                                            <label for="account-username" class="d-block text-primary mt-1">
                                                Vetor 1
                                            </label>
                                            <div>
                                                <span>
                                                    <InputFormatIndex
                                                        v-if="['index_mini_index'].includes(tipe_simulation)"
                                                        v-model="vetor_1" placeholder="Digite aqui o 1° vetor"
                                                        :disabled="!tradeTypesList.length > 0 " />

                                                    <InputFormatPrice
                                                        v-if="['action_brazil', 'dollar_mini_dollar', 'cryptocurrency', 'action_usa'].includes(tipe_simulation)"
                                                        v-model="vetor_1" placeholder="Digite aqui o 1° vetor"
                                                        :disabled="!tradeTypesList.length > 0 " />
                                                </span>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-md-auto d-flex justify-content-center">
                                        <div role="group" class="form-group">
                                            <label for="account-username" class="d-block text-primary mt-1">
                                                Vetor 2
                                            </label>
                                            <div>
                                                <span>
                                                    <InputFormatIndex
                                                        v-if="['index_mini_index'].includes(tipe_simulation)"
                                                        v-model="vetor_2" placeholder="Digite aqui o 2° vetor"
                                                        :disabled="!tradeTypesList.length > 0 " />

                                                    <InputFormatPrice
                                                        v-if="['action_brazil', 'dollar_mini_dollar', 'cryptocurrency', 'action_usa'].includes(tipe_simulation)"
                                                        v-model="vetor_2" placeholder="Digite aqui o 2° vetor"
                                                        :disabled="!tradeTypesList.length > 0 " />
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-auto d-flex justify-content-center">
                                        <div role="group" class="form-group">
                                            <label for="account-username" class="d-block text-primary mt-1">
                                                Início do trade
                                            </label>
                                            <div>
                                                <span>
                                                    <InputFormatIndex
                                                        v-if="['index_mini_index'].includes(tipe_simulation)"
                                                        v-model="start_trade" placeholder="Início do trade"
                                                        :disabled="!tradeTypesList.length > 0 " />

                                                    <InputFormatPrice
                                                        v-if="['action_brazil', 'dollar_mini_dollar', 'cryptocurrency', 'action_usa'].includes(tipe_simulation)"
                                                        v-model="start_trade" placeholder="Início do trade"
                                                        :disabled="!tradeTypesList.length > 0 " />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto p-0 ">
                                        <label for="account-username" class="d-block text-primary mt-1">
                                            &nbsp;
                                        </label>
                                        <div>
                                            <button class="btn btn-theme  opacity-8 text-nowrap " type="submit"
                                                style="width: 170px; height: 45px;"
                                                :disabled="!tradeTypesList.length > 0  || loadingSimulationGenerate">
                                                <div>

                                                    <span class="">
                                                        {{ $t("Mostrar resultado") }}
                                                    </span>
                                                    <i v-if="loadingSimulationGenerate"
                                                        class="fa fa-spinner fa-spin ml-1"
                                                        style="margin-left: 5px;"></i>
                                                    <i v-else class="fa fa-check" style="margin-left: 5px;"></i>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- Premium -->
                                <div 
                                    class="col-12 px-4 d-md-flex justify-content-md-center gap-3">

                                    <div class="col-md-auto d-flex justify-content-center">
                                        <div role="group" class="form-group">
                                            <label for="account-username" class="d-block text-primary mt-1">
                                                Risco aceitável
                                            </label>
                                            <div>
                                                <span>

                                                    <InputFormatPrice
                                                        v-if="['action_brazil', 'dollar_mini_dollar', 'cryptocurrency', 'action_usa', 'index_mini_index'].includes(tipe_simulation)"
                                                        v-model="acceptable_financial_risk"
                                                        placeholder="Risco financeiro aceitável"
                                                        :disabled="!tradeTypesList.length > 0 "
                                                        curstomStyle="color: #d1d1d18c !important;" />

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto d-flex justify-content-center">
                                        <div role="group" class="form-group">
                                            <label for="account-username" class="d-block text-primary mt-1">
                                                Percentual acerto
                                            </label>
                                            <div>
                                                <span>

                                                    <InputFormatPrice
                                                        v-if="['action_brazil', 'dollar_mini_dollar', 'cryptocurrency', 'action_usa', 'index_mini_index'].includes(tipe_simulation)"
                                                        v-model="accurate_percentage" placeholder="Percentual acerto"
                                                        :disabled="!tradeTypesList.length > 0 "
                                                        curstomStyle="color: #d1d1d18c !important;" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto d-flex justify-content-center" style="width: 170px;">
                                    </div>
                                    <div class="col-md-auto d-flex justify-content-center" style="width: 170px;">
                                    </div>

                                </div>
                            </div>
                  
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import InputFormatPrice from "../../../components/input/InputFormatPrice.vue"
import InputFormatIndex from "../../../components/input/InputFormatIndex.vue"
import { Tooltip } from 'bootstrap';

export default {
    components: {
        InputFormatPrice,
        InputFormatIndex
    },
    data() {
        return {
            tradeTypesList: [],
            listTradeTypeLoading: true,
            simulation_uuid: null,
            tipe_simulation: 'action_brazil',
            vetor_1: 0.00,
            vetor_2: 0.00,
            start_trade: 0.00,
            acceptable_financial_risk: 0,
            accurate_percentage: 0,
            nameSimulation: '',
            loadingSimulationGenerate: false,
            resultSimulation: null,

            // plan
            expirationDate: "",
            currentDateServer: "",
            simulation_level_two: false,
            daysExpiration: 0,
            email_verified: false,
        };
    },
    mounted() {
        this.typesSimulations();

        // const tooltipTriggerList = document.querySelectorAll('.custom-tooltip');
        // tooltipTriggerList.forEach((tooltipTriggerEl) => {
        //     new Tooltip(tooltipTriggerEl);
        // });
    },
    unmounted() {
        const tooltipTriggerList = document.querySelectorAll('.custom-tooltip');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            const tooltipInstance = Tooltip.getInstance(tooltipTriggerEl);
            if (tooltipInstance) {
                tooltipInstance.dispose();
            }
        });
    },
    computed: {
    },
    watch: {
    
    },
    methods: {
        ...mapActions(["simulationsGetTypes"]),
        submitSimulation() {
            window.open('https://app.trademeta.com.br/registrer', '_self');
        },
        async typesSimulations() {
            await this.simulationsGetTypes().then(rep => {
                this.listTradeTypeLoading = false;

                this.tradeTypesList = rep.simulations;
                this.simulation_uuid = this.tradeTypesList[0]?.uuid;

            }).catch(err => {
                this.listTradeTypeLoading = false;
                console.log(err);
            });
        },
    },
};
</script>
<style>
.tooltip {
    background-color: #141722;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(119, 119, 119, 0.568);
}

.tooltip .tooltip-inner {
    color: #fff;
    font-size: 14px;
    background-color: #141722 !important;
}

.tooltip .arrow::before {
    border-top-color: #141722;
}

.tooltip.show {
    opacity: 10 !important;
}
</style>

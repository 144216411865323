<template>
  <div>
    <input class="form-control py-2 dash-input-custom mr-2 mt-2 index" v-model="inputValue" type="text"
      :placeholder="placeholder" @blur="onBlur" style="width: 170px;" @focus="onFocus" @input="formatIndex"
      :disabled="disabled" />
  </div>
</template>

<script>
export default {
  name: 'InputFormatIndex',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '000.000'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.modelValue
    };
  },
  watch: {
    modelValue(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
  methods: {
    formatIndex() {
      let value = this.inputValue.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      if (value) {
        value = value.replace(/^0+/, ''); // Remove zeros à esquerda
        value = value.replace(/(\d)(?=(\d{3})+$)/g, '$1.'); // Adiciona pontos a cada 3 dígitos
        this.inputValue = value;
      } else {
        this.inputValue = '';
      }
    },
    onBlur() {
      this.formatIndex();
    },
    onFocus() {
      this.inputValue = this.inputValue.replace(/\./g, ''); // Remove pontos ao focar
    }
  }
};
</script>

<style scoped>
/* Estilos opcionais */
</style>

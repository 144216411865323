import axios from 'axios'

let api = process.env.VUE_APP_API_URL

const axiosIns = axios.create({
  baseURL: api
})

axiosIns.interceptors.request.use((config) => {
  const token = localStorage.getItem('tradeMetaToken')
  let locale = localStorage.getItem('currentLanguage')

  // if (token) {
  //   config.headers.Authorization = `Bearer ${token}`
  // }

  if (locale) {
    if (locale == 'br')
      locale = 'br'
    config.headers.locale = locale
  }

  return config
}, (err) => {
  return Promise.reject(err)
})

axiosIns.interceptors.response.use((response) => {
  return response
}, (error) => {

  // if (error?.response?.status === 401) {
  //   return window.location = '/login'
  // }

  return Promise.reject(error)
})

export default axiosIns


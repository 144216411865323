<template>
    <div class="translate_wrapper">

        <div class="translate_wrapper " :class="{ 'active': isActive }">
            <div class="current_lang" @click="display()">
                <div class="media profile-media">
                    <img :src="iconLnagCurrent" class="b-r-16 py-0" alt="" height="30" width="30" />
                </div>
            </div>

            <div class="more_lang" :class="{ 'active': isActive }">
                <div class="lang" v-for="(lang, index) in localOptions" :key="index"
                    @click.prevent="changeLocale(lang, lang.name)">

                    <i class="flag-icon " :class="lang.icon"></i>
                    <span class="lang-txt">
                        {{
                            lang.name
                        }}<span>
                            {{ lang.short }}</span>
                    </span>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { localeOptions } from '../../constants/config';

export default {
    name: 'languagePage',
    data() {
        return {
            isActive: false,
            localOptions: localeOptions,
            iconLnagCurrent: '',
        };
    },

    computed: {
        ...mapGetters({
            langIcon: 'langIcon',
            langLangauge: 'langLangauge'
        })
    },
    watch: {
        langIcon(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.imgChange(newVal);
            }
        }
    },
    mounted() {
        this.$store.dispatch('setLang', { id: localStorage.getItem('currentLanguage') || 'en', icon: localStorage.getItem('currentLanguageIcon') || 'flag-icon-us' })
        this.imgChange(this.langIcon)
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        display() {
            this.isActive = !this.isActive
        },
        handleClickOutside(event) {
            const translateWrapper = this.$el.querySelector('.translate_wrapper');
            if (this.isActive && !translateWrapper.contains(event.target)) {
                this.isActive = false;
            }
        },
        changeLocale(value, language) {
            this.$i18n.locale = value.id
            this.$store.dispatch('setLang', value);
        },
        async imgChange(langIcon) {
            if (langIcon) {
                this.iconLnagCurrent = await require(`@/assets/images/lang/${langIcon}.png`);
            }
        }
    }
}
</script>

<style>
.flag-icon {
    padding: auto;
}

.flag-icon.rounded-circle {
    width: 40px !important;
    /* ou o tamanho que preferir */
    height: 48px;
    /* garantir que a altura seja igual à largura */

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
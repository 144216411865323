<template>
  <div class="pt-2 pt-md-0">
    <div class="container-fluid">
      <div class="">
        <div class="d-flex" v-if="title">
          <h3 class="text-white">{{ $t(title.name) }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  props: {
    title: {
      default: 'home'
    },
    main: {
      default: ''
    }
  }
};
</script>

<template>
  <div class="container py-5 ">
    <div class="row" @mouseover="pauseRotation" @mouseleave="resumeRotation">
      <div class="col-1 d-flex justify-content-center align-items-center">
        <span class="btn-slider p-1 rounded px-2 pointer" @click="prevComment" :disabled="currentIndex === 0">
          <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
            style="margin-top: 4px;">
            <path d="M25 10L15 20L25 30" stroke="#00a2ff" stroke-width="4" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>

        </span>
      </div>
      <div class="col-10 p-0">
        <div class="row p-0">
          <div class="col-12 col-md-6 col-lg-4 " v-for="comment in displayedComments" :key="comment.id">
            <div class="text-start border p-2 py-3 rounded h-100 d-flex flex-column card-mini-custom-gray">
              <div class="mt-3 mb-3 px-2 flex-grow-1">
                <p>{{ comment.content }}</p>
              </div>
              <div class="align-self-start mb-2 text-white">
                <span v-for="(star, index) in comment.starRating" :key="index">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-star-fill text-success" viewBox="0 0 16 16">
                    <path
                      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                </span>
                <br>
                <small>{{ comment.author }}</small>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-1 d-flex justify-content-center align-items-center">
        <span class="btn-slider p-1 rounded px-2 pointer" @click="nextComment"
          :disabled="currentIndex + itemsPerPage >= comments.length">
          <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
            style="margin-top: 4px;">
            <path d="M15 10L25 20L15 30" stroke="#00a2ff" stroke-width="4" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>

        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      comments: [
        {
          id: 1,
          content: "Vuexy is hands down the most useful front end Bootstrap theme I ve ever used. I can t wait to use it again for my next project.",
          starRating: Array.from({ length: 5 }, () => Math.random()),
          author: 'Autor 1',
        },
        {
          id: 2,
          content: "I ve never used a theme as versatile and flexible as Vuexy. It's my go to for building dashboard sites on almost.",
          starRating: Array.from({ length: 5 }, () => Math.random()),
          author: 'Autor 2'
        },
        {
          id: 3,
          content: "This template is really clean & well documented. The docs are really easy to understand and it's always easy to find a screenshot from their website.",
          starRating: Array.from({ length: 4 }, () => Math.random()),
          author: 'Autor 3'
        },
        {
          id: 4,
          content: "I ve never used a theme as versatile and flexible as Vuexy. It's my go to for building dashboard sites on almost.",
          starRating: Array.from({ length: 4 }, () => Math.random()),
          author: 'Autor 4'
        },
        {
          id: 5,
          content: "Vuexy is hands down the most useful front end Bootstrap theme I ve ever used. I can t wait to use it again for my next project.",
          starRating: Array.from({ length: 5 }, () => Math.random()),
          author: 'Autor 5'
        },
        // Adicione mais comentários conforme necessário
      ],
      currentIndex: 0,
      itemsPerPage: 1,
      rotationInterval: null,
    };
  },
  computed: {
    displayedComments() {
      return this.comments.slice(this.currentIndex, this.currentIndex + this.itemsPerPage);
    },
  },
  methods: {
    nextComment() {
      if (this.currentIndex + this.itemsPerPage < this.comments.length) {
        this.currentIndex += this.itemsPerPage;
      } else {
        this.currentIndex = 0;
      }
    },
    prevComment() {
      if (this.currentIndex > 0) {
        this.currentIndex -= this.itemsPerPage;
      }
    },
    updateItemsPerPage() {
      const width = window.innerWidth;
      if (width >= 992) { // Large screens (>= 992px)
        this.itemsPerPage = 3;
      } else if (width >= 768) { // Medium screens (>= 768px)
        this.itemsPerPage = 2;
      } else { // Small screens (< 768px)
        this.itemsPerPage = 1;
      }
    },
    startRotation() {
      this.rotationInterval = setInterval(this.nextComment, 2000);
    },
    pauseRotation() {
      clearInterval(this.rotationInterval);
    },
    resumeRotation() {
      this.startRotation();
    },
  },
  mounted() {
    this.updateItemsPerPage();
    window.addEventListener('resize', this.updateItemsPerPage);
    this.startRotation();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateItemsPerPage);
    this.pauseRotation();
  },
};
</script>

<style scoped></style>

<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0 ">
    <div class="media profile-media">
      <span v-if="avatarSrc">
        <img class="b-r-16" :src="avatarSrc" alt="Profile" height="30" width="30">
      </span>
      <span v-else>
        <img class="b-r-16" src="@/assets/images/dashboard/profile.png" alt="" height="30" width="30" />
      </span>
    </div>
    <div class="profile-dropdown onhover-show-div" :class="{ active: notification }" style="">
      <h6 class="font-size-13 mb-0 dropdown-title p-0 py-1 m-0">
        <span v-if="returnUser && returnUser.fullname" style="margin-right: 10px;">
          {{ $t("Bem vindo") }}, <strong>{{ limitString(returnUser.fullname) }}</strong>
        </span>
        <div class="text-center">
          <div class="menu-title font-size-11 py-0 m-0 w-100">
            <div class="text-white">
              <b class="text-theme">{{ planName }}</b><br>
              {{ $t("Vencimento") }}: <b :class="{
                'text-theme': daysExpiration > 0,
                'text-danger': daysExpiration <= 0
              }"> {{ daysExpiration > 0 ?
                daysExpiration : 0 }}
                {{ $t("dias") }}</b>
            </div>
          </div>
        </div>
      </h6>

      <ul class="mb-2">
        <li class="b-l-primary border-4">
          <router-link to="/"><vue-feather type="user">
            </vue-feather>
            <span style="margin-left: 10px;">
              {{ $t('Account') }}
            </span>
          </router-link>
        </li>


        <!-- <li class="b-l-primary border-4">
          <router-link :to="{ name: 'supportTickets' }">
            <vue-feather type="dollar-sign">
            </vue-feather>
            <span style="margin-left: 10px;">
              {{ $t('Financeiro') }}
            </span>
          </router-link>
        </li> -->
        <li class="b-l-primary border-4">
          <a @click="logout">
            <vue-feather type="log-in">
            </vue-feather>
            <span style="margin-left: 10px;">
              {{ $t('Log out') }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
export default {
  name: 'Profile',
  data() {
    return {
      avatarSrc: null,
      planName: "",
      expirationDate: "",
      currentDateServer: "",
      daysExpiration: 0,
    };
  },
  computed: {
    ...mapGetters(["returnUser"]),
  },

  mounted() {
    this.imgChange();
  },
  watch: {

    returnUser: {
      immediate: true,
      handler(newVal, oldVal) {

        if (newVal !== oldVal) {
          this.imgChange();

          //data expiração plano clinica
          this.expirationDate = this.returnUser?.subscription_detail?.expiration_date

          //nome plano clinica
          this.planName = this.returnUser?.subscription_detail?.subscription_plan?.name_label ?? ''
          //data atual servidor
          this.currentDateServer = newVal?.server_date ?? ''

          this.daysExpiration = this.daysUntilExpiration(this.expirationDate, this.currentDateServer);

        }
      },
    },
  },
  methods: {
    ...mapActions(["userLogout"]),
    logout: function () {
      // localStorage.removeItem('tradeMetaToken');
      // this.userLogout().then(() => {
      //   this.$router.replace("/login");
      // }).catch(error => {
      //   this.$router.replace("/login");
      // });
    },
    imgChange() {

      if (this.returnUser?.avatar) {
        this.avatarSrc = this.returnUser?.avatar
          ? `${process.env.VUE_APP_API_URL}/assets/${this.returnUser?.avatar}`
          : null;
      }
    },
    limitString(nome) {
      const palavras = nome.trim().split(/\s+/); // Dividir em palavras, removendo espaços extras
      const nomeAbreviado = palavras.slice(0, 1).join(' '); // Combina as primeiras duas palavras
      //return nomeAbreviado;
      if (nomeAbreviado.length <= 12) {
        return nomeAbreviado;
      } else {
        return nomeAbreviado.substring(0, 12);
      }
    },
    daysUntilExpiration(expirationDate, currentDateServer) {
      const expiration = moment(expirationDate);
      const current = moment(currentDateServer);
      return expiration.diff(current, 'days');
    }
  },
};
</script>

<template>
    <div class="mt-0 modal fade" id="modalWithdrawBonus" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> {{ $t("Coletar Comissão") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal">
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="dashboardMyResults" class="fs-5 text-center">{{ $t("Montante a ser coletado") }}: <span
                            class="text-info">{{
                                moneyFormat(dashboardMyResults.blockedBonus) }}</span>
                    </div>
                    <!-- teste aqui -->
                </div>
                <div class="mt-3 text-center" v-show="showAlert">
                    <div class="demo-spacing-0 text-cente">
                        <div class="alert" :class="colorAlert">
                            <span>{{ textAlert }}</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end p-3">

                    <button type="button" class="purchase-btn btn btn-primary btn-hover-effect f-w-500"
                        @click="collectBonus()">
                        <div v-if="!loadingBonusWd">
                            {{ $t("Coletar Comissão") }}
                        </div>
                        <div v-else role="status" class="spinner-grow spinner-grow-sm">
                            <span class="sr-only"> {{ $t("Aguarde...") }}</span>
                        </div>
                    </button>

                </div>
            </div>
        </div>
    </div>
    <div class="col-xxl-4 col-sm-6 box-col-6">
        <div class="row">
            <div class="col-xl-12" v-for="purchaseSales in purchaseSalesDetails1" :key="purchaseSales">
                <div class="card widget-1">
                    <div class="card-body">
                        <div :class="['widget-content', !purchaseSales.totalLiquidityBnb && 'p-2']">
                            <div :class="purchaseSales.widgetClass">
                                <div class="bg-round">
                                    <svg class="svg-fill">
                                        <use
                                            :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${purchaseSales.svgIcon1}`">
                                        </use>
                                    </svg>
                                </div>
                            </div>
                            <div class="w-75">
                                <p class="font-size-18 mb-0 text-light">{{ purchaseSales.number }}</p>
                                <span class="f-light">
                                    {{ purchaseSales.title }}
                                </span>
                            </div>
                            <div v-if="false" class="w-100">
                                <div class="border px-0 text-light rounded-2 bg-dark mt-0">
                                    <div class="row p-2">
                                        <div class="col-12 p-1">
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACaUlEQVR4Xu2aPW7CMBiGOQJH6BE4AkfoEThCj8ANIiWwsCAmNnoBJCQ2JtiYUBc2Bla2Vl9Vq+R1En/+xQY/0rMQ298PdpSU9nqZTCaTyfCpqu+onU4HmLI7iqIvBYxRb2CgeL1i6vZU1aYhULw6BwOkoDNw4XQcYin6TCZfDQunozW4YIoagwt1GBqM32lRvGFpaqpqLC3UYWgwvlJtcAGFocH4SsuywBLbwckMQ4PxWbKg84ITGYYG4zNlPCHKk1gOVyuW+8sFa6mB49vE+Gw70bzxmbg5n7HmGjjei63gQA/G2wAc5MkoGkDWMLzxdUnnVFUsB1rD6sy3WZaj/wbgRQP7sxnm7o23xUKKb+Rf8RvpgoaUzKOw3hn0oid9qGkTtG0Hy6U0llQdCxxPUpM/Tycc+ov1bpA+0FQwWq+la02aNAClb15gvQtc3QO4umjAvVYNqIEXmRJ0A8TPVbp4siNM59aLF+AghvfQYy5edy0+Shs1oBMcrFAFbfmP7VZ7l9A9RXVcCJynVAk9IOAkhZRsaMa7nZQHw3cstxmDJgiLwwFzdcb8eJTisdX+yYwm4CIW0lmlb+16u2FdNWiM0bnuUrt4geMmkKpzjeOtNS7+HlzUwqANcAoubmiwBngBgxgYpAFewWCaem+Adyz/ScJrA4KCwZl6a8BDwCQYemnAQ8FkFNIfSvBN0OqtMAowqVBGBSbnV8ZPXI9ATtSHkRYvkBN2axJg0q5MCkze1iTBIkxNGixG16egqvZSYSonkzkukzZUEBbZ5tMVL+A04WmLF2DB6EuARb9U8YKXLj6TyWQycfADMiVWaB1DBnAAAAAASUVORK5CYII="
                                                alt="usdt" style="width: 18px; margin-left: 10px; margin-top: 1px;">
                                            <span class="font-size-8" style="margin-left: 2px;">
                                                USDT</span>
                                            {{ moneyFormatUSDTNoName(purchaseSales.totalLiquidityUsdt) }}

                                        </div>
                                        <hr class="mt-2 w-75 p-0 mb-0" style="margin-left: 19px;">
                                        <div class="col-12 p-1">
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAFMklEQVR4XtVbvW4TQRhMSWwipYYngCeACsp0pEsJFZTp8gapaXgD6F0gETsIWaRIARIopAAJJAcJCgq4s5M4/zE3l6y1nv253fXe2R5plOR2787f7PfN7u05c3MVIGksLiYbNx92W/XnaavWSFv1TrpZT7Kfg5xXv3fQhj7oi3P4OjOD7ub8ahZUexjguGzW271W/THfZ+qAkYsauMJaI3mzsMz3nThG0rky1jr8OSpHPuJ57fKHq4q1zkRKI9moP5nMqJtYoRBwZ/UDTAdLnzmma9RNLMkb8rRXbjadjF4OfINZIccRhFkaeebYmZCWNMV1390enOy9GAgc7j5T+kRkwnE5oUy3vzxLh8ELXPR/Kv1i0Xt2KCPte1t3OWYtyhLCqxzSyKl//GNdO+omQIQSysKtFGKO/tm/LY5tBEff1wenf17z4REcZ17B1w0llu4cr4I0wuizyelw9ndr2P+iv8fNI4iXDQWLpDRC8KApoMuzZND/uqb0Fzz59ZJPGSJiJphLQdM5mP3dpxxDlhm3lH7M3vs7fNpg/8OS0m8cctw5ktbCMnd0YT9LT8Dk3giIj4EHH5eGAeqEwXm64ygvAcws3O5CrRdc79UpnW1kkzvv7Th9KPYHV9c/+ramzCZBpdGst0eC93V+Dpyxv31POQfEqNrq3OYPmDFsOMxKjs+xsducXx0K4LuPZxKgyOQEdSbpMpI28XwFGMkCpbGALMB5d0c76vAHpCwWQrp6xqgeflpRju9v38/vgTWCrqRgsFwK3gK0JDPkhiKyAEdZgNxHNjnAZJJM2eQEdCJwFgULkLT9H3psAmCki6ALiAXTQe4fQ4D8Iel6Z1dptPEoq3MZsgAuDz3IBlxDnMOCmiB/BlkA/G6abm3Mp8Or11VqowsPPq/kN/cVIBTyvXFfmKLOW1yJ2IPmfyZMSfxelQCnWfD8ObzZrDWC1v+oVz4mWJUAMmGcOl8pZvZwFLLVjZrFqo+Pgy4mGAq+lyCmxBATTPFgpDlYSNm0TNOb7qEmBFhc8bVBni4DBRiMLQCgWweAwiRDgdWhyeSQgTLCBQgsARkmAQSLngEYLsGwsC7naJiXQEfTYKVNAJiR6ckOu0BFwPKYzxM7TPKxOALABAOmwSIBBE5+vxpxZ90mCUO+D2Yb+V5yWxQBMA2GLIRcBQBkk/QVgCG3xRAgXwiFLIV9BABE27QJsL9540H0h6FZEmD4xogbimgTAMTUJe/giOOhAug2RWMIEG0/AGbHfUAx9Ym/fQXAIkg3KyDL4m6IRNoSY9dn+grAxKwAU9XBWwB5Syz/YiN3sNAkgIBp9TaOANgms8FXAOVlqW8WgEUwZQPXLyC/KpOJrXAbcC0+p5CY/xkh06HLEtfkD7JJ6kwOPO9+ka6kQucPLuy9rT3i+HNwRx/a0pv7utIEk2Cu5LiHCHkwkmnKBtGOOjaVBcgbLQyku2+tq6zgDTGyQa5z+fhVIKOvwuQvUMjXEYA/hGx6qiwIHgjxAhMFxN9cJsgG8XJVgM8PMjkDFec3YdxSMJEF0IHPiUeH0RfwfVnqykkK4Dz6AiEPSS607RWaFlDj0vtrcgJlloJskjA53cvVOPRIfR3KKgfQ9C2QWPROexP4wrNCjmMslJkJsRlt5BlphEVS6dy0fA0uBsqaHWIw2O19cV0OHf4AE2M26qWlvQ2TF6LWyXd2J410EiKUXeshwEZDyJsmZzbr7akY8SJc/2NlWwkglFng2Lfk+8wM4M4YuW7T4d/nsz7oW5Wj/wdXGukMTSgLegAAAABJRU5ErkJggg=="
                                                alt="bnb" style="width: 18px; margin-left: 10px; margin-top: 1px;">
                                            <span class="font-size-8"
                                                style="margin-left: 5px; margin-right: 6px;">BNB</span>
                                            {{ moneyFormatBnbNoName(purchaseSales.totalLiquidityBnb) }}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xxl-4 col-sm-6 box-col-6">
        <div class="row">
            <div class="col-xl-12" v-for="purchaseSales in  purchaseSalesDetails2 " :key="purchaseSales">
                <div class="card widget-1">
                    <div class="card-body">
                        <div class="widget-content p-2">
                            <div :class="purchaseSales.widgetClass">
                                <div class="bg-round">
                                    <svg class="svg-fill">
                                        <use
                                            :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${purchaseSales.svgIcon1}`">
                                        </use>
                                    </svg>
                                </div>
                            </div>
                            <div class="w-100">
                                <p class="font-size-18 mb-0 text-light">{{ purchaseSales.number }}</p>
                                <span class="f-light">
                                    {{ purchaseSales.title }}
                                </span>
                            </div>
                            <span v-if="purchaseSales.withdrawBonusButton">
                                <button type="button" :disabled="!purchaseSales.withdrawBonus"
                                    class="btn btn-sm btn-primary btn-hover-effect" style="width: 140px;"
                                    data-bs-toggle="modal" data-bs-target="#modalWithdrawBonus">
                                    {{ $t("Coletar Comissão") }}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
        <div class="row">
            <div class="col-xl-12" v-for="purchaseSales in purchaseSalesDetails3" :key="purchaseSales">
                <div class="card widget-1">
                    <div class="card-body">
                        <div class="widget-content">
                            <div :class="purchaseSales.widgetClass">
                                <div class="bg-round">
                                    <svg class="svg-fill">
                                        <use
                                            :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${purchaseSales.svgIcon1}`">
                                        </use>
                                    </svg>

                                </div>
                            </div>
                            <div class="w-100">
                                <p class="font-size-18 mb-0 text-light">{{ purchaseSales.number }}</p>
                                <span class="f-light">
                                    {{ purchaseSales.title }}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import {
    purchaseSalesDetails1,
    purchaseSalesDetails2
} from "../../../data/dashboard/default"
export default {
    props: {
        dashboardMyResults: Object,
    },
    data() {
        return {
            loadingBonusWd: false,
            showAlert: false,
            colorAlert: "alert-warning",
            textAlert: "",

            purchaseSalesDetails1:
                [

                ],
            purchaseSalesDetails2:
                [

                ],
            purchaseSalesDetails3:
                [

                ]
        }
    },
    mounted() {

        if (this.dashboardMyResults) {
            this.handleDist(this.dashboardMyResults);
        }


    },
    watch: {
        dashboardMyResults(newValue) {
            if (newValue) {
                this.handleDist(newValue);
            }
        }
    },
    methods: {
        handleDist(dashboardMyResults) {

            this.purchaseSalesDetails1 = [
                {
                    widgetClass: "widget-round success",
                    svgIcon1: "profit",
                    svgIcon2: "halfcircle",
                    number: this.moneyFormat(dashboardMyResults.totalLiquidity),
                    totalLiquidityUsdt: dashboardMyResults?.totalLiquidityUsdt || 0,
                    totalLiquidityBnb: dashboardMyResults?.totalLiquidityBnb || 0,
                    title: this.$t('Valor atual do investimento'),
                    growthClass: "font-success f-w-500",

                },
                {
                    widgetClass: "widget-round warning",
                    svgIcon1: "course-1",
                    svgIcon2: "halfcircle",
                    number: dashboardMyResults?.totalLicensesOperation || 0,
                    title: this.$t("Licenças em operação"),
                    growthClass: "font-warning f-w-500",

                },
            ];
            this.purchaseSalesDetails2 = [
                {
                    widgetClass: "widget-round primary",
                    svgIcon1: "cash-coin",
                    svgIcon2: "halfcircle",
                    number: this.moneyFormat(dashboardMyResults?.blockedBonus),
                    title: this.$t("Comissão Acumulados"),
                    withdrawBonus: (dashboardMyResults?.blockedBonus > 0 ? true : false) && dashboardMyResults?.bonusWithdrawalSatus,
                    withdrawBonusButton: true,
                    growthClass: "font-primary f-w-500",

                },
                {
                    widgetClass: "widget-round secondary",
                    svgIcon1: "doller-return",
                    svgIcon2: "halfcircle",
                    number: this.moneyFormat(dashboardMyResults?.withdrawalBonus),
                    title: this.$t("Comissão coletados"),
                    growthClass: "font-warning f-w-500",

                },

            ];
            this.purchaseSalesDetails3 = [
                // {
                //     widgetClass: "widget-round primary",
                //     svgIcon1: "fill-charts",
                //     svgIcon2: "halfcircle",
                //     number: dashboardMyResults?.runningRobots || 0,
                //     title: "Em operação",
                //     growthClass: "font-primary f-w-500",

                // },
                // {
                //     widgetClass: "widget-round success",
                //     svgIcon1: "rate",
                //     svgIcon2: "halfcircle",
                //     number: this.moneyFormat(dashboardMyResults?.initialValue),
                //     title: "Valor investido",
                //     growthClass: "font-success f-w-500",

                // }
            ];

        },
        collectBonus() {
            this.loadingBonusWd = true;

            this.$store
                .dispatch("collectBonus")
                .then(async (response) => {
                    this.loadingBonusWd = false;
                    this.$store.dispatch("recoverUserData");
                    setTimeout(() => {
                        this.$toast.show(this.$t('Comissão de Rede com sucesso!'), {
                            theme: 'outline', position: 'top-right', type: 'info', duration: 2000
                        });
                    }, 500);

                })
                .catch((error) => {
                    this.loadingBonusWd = false;
                    this.$toast.show(this.$t(error.message), {
                        theme: 'outline', position: 'top-right', type: 'warning', duration: 2000
                    });
                });
        }
    }
}
</script>

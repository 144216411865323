import { createRouter, createWebHistory } from "vue-router";
// *===============================================---*
// *--------- Editados ---- ----------------------------*
// *===============================================---*

/* Dashboards */
import DefaultDashboard from '../views/dashboard/default';


/* plans subscriptrion */
import subscriptionPlans from '../views/subscription/subscriptionPlans'

// *===============================================---*
// *--------- outros ---- ----------------------------*
// *===============================================---*
import Body from '../components/body';
/* Buttons */
// error
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';


const router = createRouter({
  history: createWebHistory(),
  routes: [

    /*Home page */
    {
      path: '/',
      component: Body,
      children: [
        {
          path: '',
          name: 'DefaultDashboard',
          component: DefaultDashboard,
          meta: {
            title: 'Home',
            action: "read",
            layout: "dashboard",
            resource: "Public",
          }
        },
      ]
    },

    /* plans */
    {
      path: '/subscription-plans',
      component: Body,
      children: [
        {
          path: '',
          name: 'subscriptionPlans',
          component: subscriptionPlans,
          meta: {
            title: 'Plans',
            action: "read",
            resource: "Public",
          }
        },
      ]
    },
    /* errors */
    {
      path: "/not-authorized",
      name: "not-authorized",
      component: Error403,
      meta: {
        title: 'Error403',
        action: "read",
        resource: "Public",
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        title: 'Error404',
        action: "read",
        resource: "Public",
      }
    },

    /* continuação */

    // *===============================================---*
    // *--------- ERROR ---- ----------------------------*
    // *===============================================---*
    {
      path: '/:catchAll(.*)',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach(async (to, _, next) => {


  // Set the page pageTitle or a default pageTitle
  if (to.meta?.title) {
    document.title =
      `${process.env.VUE_APP_TITLE} - ${to.meta.title}` ||
      process.env.VUE_APP_TITLE;
  }



    // await store.dispatch("setListMenu", listData).catch((err) => {
    //   //console.log('deu erro');
    // });




  // If navigating to a public page, do not perform redirection

  if (to.meta.resource === "Public") {
    return next();
  }
  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router
<template>
    <div class="container">

        <div class="row widget-grid">
            <h4 class="text-white text-center mt-5">
                <strong>Nossos planos</strong> foram feitos para sua necessidade
            </h4>
            <p class="text-center">
                Veja qual plano atende melhor suas expectativas e faça um teste de 15 dias grátis!
            </p>
            <div v-if="loadingSubscriptionPlans && !subscriptionsPlans" class="d-flex justify-content-center mb-5">
                <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!loadingSubscriptionPlans && subscriptionsPlans">
                <p class="text-center">
                    <span class="" style="margin-right: 6px;">
                        Plano
                        <strong>Mensal</strong>
                    </span>
                    <label class="custom-switch">
                        <input type="checkbox" :checked="planType == 'monthly' ? false : true"
                            @change="planType = $event.target.checked ? 'yearly' : 'monthly'">
                        >
                        <span class="custom-slider"></span>
                    </label>
                    <span class="text-theme" style="margin-left: 5px;">
                        Plano <strong>Anual</strong>
                    </span>
                    <!--        <img src="@/assets/images/Arrow.png" width="130" class="mb-4" alt="trademeta" /> -->
                </p>
                <div class=" mt-0 mb-5">
                    <div class="card-body text-white">
                        <div class="row">
                            <div class="col-12 p-0 rounded border  table-responsive">
                                <table class="table fixed-col-1 text-center p-2 py-3 h-100">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-start">
                                                FEATURES
                                                <p>
                                                    <small class="text-theme">
                                                        Lista de funcionalidades
                                                    </small>
                                                </p>
                                            </th>
                                            <th scope="col" v-for="item of pricingData" :key="item.id">
                                                <strong class="text-uppercase mb-2">
                                                    {{ item.plan.name_label }}
                                                </strong>
                                                <div class="h4 text-success">
                                                    <span v-if="item.type_subscription == 'free'">
                                                        {{ item.title }}
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="8" cy="8" r="6" stroke="#00a2ff"
                                                                stroke-opacity="0.5" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M7.99935 11.3333V11.34" stroke="#00a2ff"
                                                                stroke-opacity="0.5" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M7.99935 9.00001C7.97413 8.55542 8.24593 8.14773 8.66602 8.00001C9.35315 7.73724 9.79956 7.06935 9.77959 6.33396C9.75961 5.59857 9.27761 4.95589 8.57722 4.73081C7.87684 4.50573 7.11067 4.74727 6.66602 5.33334"
                                                                stroke="#00a2ff" stroke-opacity="0.5" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                    </span>
                                                    <span v-else>
                                                        <strong class="px-1 text-theme">
                                                            {{ formatMoneyBrl(item.price) }}
                                                        </strong>
                                                        <sub class="text-theme font-size-12">por {{ planType ==
                                                            "monthly" ? "mês" : "ano" }}</sub>
                                                    </span>

                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    Simulações sem limite
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.name != 'plan_free'">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    Lista com simulações salvas
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.name != 'plan_free'">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    5 saídas para cada simulação
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.name != 'plan_free'">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <!--        Acesso antecipado dos materiais -->
                                        <tr>
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    Acesso antecipado dos materiais
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.simulation_level_two">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <!--                 Detalhamento Nivel 2 -->
                                        <tr>
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    Detalhamento Nivel 2
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.simulation_level_two">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <!-- Bate-papo com os especialistas -->
                                        <tr>
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    Bate-papo com os especialistas
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.simulation_level_two">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <!-- Grupo exclusivo para dicas e dúvidas -->
                                        <tr>
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    Grupo exclusivo para dicas e dúvidas
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.simulation_level_two">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <!-- Participação no chat durante as lives  -->
                                        <tr v-if="false">
                                            <th scope="row" class="text-start">
                                                <strong>
                                                    Participação no chat durante as lives
                                                </strong>
                                            </th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <span v-if="item.plan && item.plan.simulation_level_two">
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#00a2ff"
                                                            fill-opacity="0.08" />
                                                        <path d="M6.41667 10L9.33333 12.9167L15.1667 7.08333"
                                                            stroke="#00a2ff" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span v-else>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" width="20" height="20" rx="10" fill="#A8AAAE"
                                                            fill-opacity="0.08" />
                                                        <path d="M14 6.5L7 13.5" stroke="#A8AAAE" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 6.5L14 13.5" stroke="#A8AAAE" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row"></th>
                                            <td v-for="item of pricingData" :key="item.id">
                                                <div
                                                    v-if="currentSignedPlan?.subscription_plans_id == item.subscription_plans_id">
                                                    <button class="btn btn-theme mt-2 opacity-8 text-nowrap mr-2"
                                                        @click="subscribePlan(item)"
                                                        :disabled="loadingCreateInterest || item.plan.id < 2">
                                                        {{ item.plan.name_label }} adquirido
                                                    </button>
                                                </div>
                                                <div v-else>
                                                    <button class="btn btn-theme mt-2 text-nowrap mr-2"
                                                        @click="subscribePlan(item)" :disabled="loadingCreateInterest">
                                                        <i v-if="loadingCreateInterest"
                                                            class="fa fa-spinner fa-spin"></i>
                                                        <span class="px-2">
                                                            Adquirir {{ item.plan.name_label }}
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Modal } from 'bootstrap';
export default {
    props: {
        dashboardMyResults: Object,
    },
    data() {
        return {
            loadingSubscriptionPlans: false,
            subscriptionsPlans: null,
            pricingData: [],
            pricinfDataMonthly: [
            ],
            pricinfDataYearly: [
            ],
            pricinfDataFree: [
            ],
            planType: "monthly",
            selectedPlan: null,
            currentSignedPlan: null,

            loadingCreateInterest: false,
        }
    },
    mounted() {
        this.handleSubscriptionPlans();
    },
    watch: {
        planType: function (val) {
            this.changePlan(val);
        },
        pricingData: function (val) {
            //console.log(val);
        }
    },
    computed: {
        ...mapGetters(["returnUser", "returnModalWithdrawRequestInstance"])
    },
    methods: {
        changePlan(plan) {

            //console.log(this.pricinfDataMonthly[0].plan);
            switch (plan) {
                case "monthly":
                    this.pricingData = this.pricinfDataMonthly;
                    break;
                case "yearly":
                    this.pricingData = this.pricinfDataYearly;
                    break;
            }

            if (this.currentSignedPlan?.subscription_plans_id) {

                this.selectedPlan = this.pricingData.find(
                    (item) => item.subscription_plans_id === this.currentSignedPlan?.subscription_plans_id
                );

            }

        },
        handleSubscriptionPlans() {
            this.loadingSubscriptionPlans = true;
            //subscriptionsPlansGet
            this.$store.dispatch("subscriptionsPlansGet").then((response) => {
                this.subscriptionsPlans = response;

                //this.purchaseLastItemPedding = resp.purchaseLastItemPedding;
                this.currentSignedPlan = response.current_subscription;
                for (const plan of response.plans_subscription) {
                    for (const subscription of plan.subscription) {
                        switch (subscription.type_subscription) {
                            case "free":
                                subscription.plan = plan;
                                this.pricinfDataMonthly.push(subscription);
                                this.pricinfDataYearly.push(subscription);
                                break;
                            case "monthly":
                                subscription.plan = plan;
                                this.pricinfDataMonthly.push(subscription);
                                break;
                            case "yearly":
                                subscription.plan = plan;
                                this.pricinfDataYearly.push(subscription);
                                break;

                        }
                    }
                }
                this.changePlan(this.planType);

                this.loadingSubscriptionPlans = false;
            }).catch((erros) => {
                console.log(erros);
                this.loadingSubscriptionPlans = false;
                this.$swal({
                    icon: 'error',
                    title: 'Erro ao carregar os planos de assinatura',
                    text: 'Tente novamente mais tarde',
                });
            });

            setTimeout(() => {
                this.loadingSubscriptionPlans = false;
            }, 2000);
        },
        //assinar plano
        subscribePlan(plan) {
            // abrir na mesma aba
            window.open('https://app.trademeta.com.br/registrer', '_self');
        }
    }
}
</script>

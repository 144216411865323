import api from "@/libs/axios";

const dashboard = {
    state: {
        openModalPurchasePlanInstance: null,
    },
    getters: {
        returnModalWithdrawRequestInstance: state => state.openModalPurchasePlanInstance,
    },
    actions: {
        openModalWithdrawRequest({ commit }, instance) {
            //console.log(instance);
            commit("addModalPurchasePlanRequest", instance);
        },
    },
    mutations: {
        addModalPurchasePlanRequest: (state, instance) => {
            state.openModalPurchasePlanInstance = instance
        },
    },
}

export default dashboard;
import { createStore } from 'vuex'

import layout from './modules/layout';
import menu from './modules/menu';

import bootsrap from "./modules/bootsrap"
import language from "./modules/language"
import dashboard from "./dashboard"
import subscriptionsPlans from "./subscription-plans"
import modals from "./modals"

export default createStore({
  state: { langIcon: '', langLangauge: '', isActive: false },
  getters: {
    langIcon: (state) => { return state.langIcon },
    langLangauge: (state) => { return state.langLangauge }
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem('currentLanguage', payload?.id ? payload.id : payload.name);
      localStorage.setItem('currentLanguageIcon', payload.icon);
      state.langIcon = payload.icon || 'flag-icon-us'
      state.langLangauge = payload?.id ? payload.id : payload.name || 'en'
    },
    change(state) {
      state.isActive = !state.isActive
    }
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload);
    }
  },
  modules: {
    alert,
    layout,
    menu,
    dashboard,
    subscriptionsPlans,
    language,

    bootsrap,
    modals
  }
});


import api from "@/libs/axios";

const dashboard = {
    state: {
        simulationsTypes: [],
    },
    getters: {
        returnSimulationsTypes: state => state.simulationsTypes,
    },
    actions: {
        /* buscar tipos de simulação trade */
        simulationsGetTypes: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/simulation-public/types`).then(response => {
                    commit('SET_SIMULATION_TYPES', response?.data?.simulations)
                    resolve(response?.data)
                }).catch(error => {
                    reject(error.response?.data)
                })
            })
        },

     },
    mutations: {
        SET_SIMULATION_TYPES: (state, simulationsTypes) => {
            state.simulationsTypes = simulationsTypes
        },
    },
}

export default dashboard;